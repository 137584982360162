@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$filterbarHeight: size("filterbar-height");
$filterbarHeightSmall: size("filterbar-height--small");
$headerHeight: size("header-height");
$headerHeightSmall: size("header-height--small") + size("user-header-height");;
$userHeaderHeight: size("user-header-height");

:export {
  small: strip-unit(breakpoint("small"));
  filterbarHeight: strip-unit($filterbarHeight);
  filterbarHeightSmall: strip-unit($filterbarHeightSmall);
  headerHeight: strip-unit($headerHeight);
  headerHeightSmall: strip-unit($headerHeightSmall);
  userHeaderHeight: strip-unit($userHeaderHeight);
}

.block {
  margin-top: -#{size("header-margin")};
}

.filterbar {
  border-bottom: 1px solid color("border--muted");
  height: $filterbarHeightSmall;

  @media (min-width: breakpoint("small")) {
    height: $filterbarHeight;
  }

  &__fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: -1px 3px 15px 0px rgba(141, 151, 166, .16);
    z-index: z-index("system");
    transition: transform transition("duration--organisms") transition("ease-ease");
    transform: translateY($headerHeightSmall);
    // will-change: top;

    @media (min-width: breakpoint("small")) {
      transform: translateY($headerHeight + $userHeaderHeight);
      z-index: z-index("system") - 1;
    }

    &_header_hidden {
      transform: translateY(0);

      @media (min-width: breakpoint("small")) {
        transform: translateY($userHeaderHeight);
      }
    }
  }

  &_placeholder {
    height: $filterbarHeightSmall;

    @media (min-width: breakpoint("small")) {
      height: $filterbarHeight;
    }

    &__hidden {
      display: none;
    }
  }
}

.listWrapper {
  margin-top: size("gutter--large");
  margin-bottom: size("gutter--large");
}
