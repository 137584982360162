@import "theme/ui-components/_variables.scss";

.block {
  background: color("body");
}

.container {
  margin-bottom: size("gutter--large") * 2;

  position: fixed;
  z-index: z-index("system");
  top: 0;
  left: 0;
  right: 0;
}
