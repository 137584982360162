@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  @media (min-width: breakpoint("medium")) {
    display: flex;
    @include stretch(math.div(size("gutter--large"), 2));
    @include clearfix();
  }
}

.left {
  @media (min-width: breakpoint("medium")) {
    padding: 0 math.div(size("gutter--large"), 2);
    float: left;
    width: 67%;
  }
}

.right {
  @media (max-width: breakpoint("medium") - 1px) {
    margin-top: size("gutter--large");
  }

  @media (min-width: breakpoint("medium")) {
    padding: 0 math.div(size("gutter--large"), 2);
    float: right;
    width: 43%;
  }
}
