@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.priceContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: size("gutter");
}

.mainPayment {
  font-size: size("font--medium");
  margin: 0 !important;
}

.secondaryPayment {
  display: block;
  margin-top: 0 !important;
  font-size: size("font--small");
  color: color("text--muted");

  @media (max-width: breakpoint("small") - 1px) {
    margin-bottom: size("gutter") !important;
  }
}

.badges {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: color("primary");

  & > * {
    display: flex;
    align-items: center;
    font-size: size("font--small");
    padding: math.div(size("gutter--mini"), 2.7) 0;
  }

  & > * + * {
    margin-top: size("gutter--mini");
  }

  svg {
    width: 15px;
    height: 15px;
    margin-right: size("gutter--mini");
  }
}
