@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  textColor: color("text");
  colorMuted: color("text--muted");
}

.block {
  margin-top: size("gutter");

  &.loading {
    opacity: .6;
  }
}

.orParagraph {
  margin: size("gutter") 0;
  text-align: center;
}

.button {
  display: block;
  width: 100%;
  margin-bottom: size("gutter");
}

.disabled {
  opacity: 0.6;

  h2 {
    color: color("text--muted");
  }
}

.terms {
  user-select: none;

  & > label {
    margin-left: size("gutter--small");
  }

  margin: -#{size("gutter")} -#{size("gutter")} size("gutter") -#{size("gutter")};
  padding: size("gutter");

  border-bottom: 1px solid color("disabled");
}

.editButton {
  font-size: size("font--small") !important;
  padding: .4rem .4rem .2rem .4rem !important;
}

.editIcon {
  height: 1.3rem;
  width: 1.3rem;
  margin: -.5rem 0;
  top: -2px;
  transform: rotate(180deg);
}

.friendsInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid color("disabled");
  @include stretch(size("gutter"));
  padding-left: size("gutter");
  padding-right: size("gutter");
  padding-top: size("gutter");
  margin-top: size("gutter");

  h2, p {
    font-size: size("font--small");
  }

  h2 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    color: color("text--muted");
  }

  & > *:first-child {
    margin-right: size("gutter--small");
  }

  & > *:last-child {
    flex: 1 1 auto;
  }
}

.mastercardInfo, .cards {
  display: flex;
  border-top: 1px solid color("border--muted");
  @include stretch(size("gutter"));
  padding: size("gutter");
}

.mastercardInfo {
  margin-top: size("gutter");
  font-size: size("font--small");

  &Inner {
    display: flex;
    align-items: center;
  }

  &Card {
    min-width: 71px;
    min-height: 49px;
    margin-right: size("gutter--small");
    background: url("/assets/images/card.png") no-repeat;
  }

  p {
    margin-top: 0;
  }

  &Header {
    display: block;
  }

  &Body {
    color: color("text--muted");
  }
}

.cards {
  padding-bottom: 0;
  justify-content: center;
  align-items: center;

  .visa path {
    fill: rgb(26, 31, 113);
  }

  &Inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > svg {
      height: 24px;
      width: auto;
      margin: size("gutter--small");
      flex-basis: 20%;
    }
  }
}

.stripe {
  &Error {
    margin: size("gutter--small") 0;
    margin-bottom: 0;
    color: color("negative");
  }

  &Row {
    & + & {
      margin-top: math.div(size("gutter"), 2);
    }
  }

  &Split {
    @include stretch();
    @include clearfix();
  }

  &Left, &Right {
    width: 50%;
    float: left;
    padding: 0 math.div(size("grid-gutter"), 2);
  }

  &Button {
    display: block;
    width: 100%;
    margin-top: size("gutter");
    margin-bottom: size("gutter");
  }
}

.icons {
  display: flex;
  justify-content: center;
  margin-bottom: size("gutter");

  &__icon {
    height: 24px;
    width: 64px;

    &:not(:first-child) {
      margin-left: size("gutter--small");
    }
  }
}

.terms {
  .link {
    color: color("primary");
    cursor: pointer;
  }
}

.modalWrapper {
  background: #000;
}

.modal {
  padding: 0px;
  margin: 0px;
  width: 75vw;
  height: 75vh;
  overflow: scroll;

  .container {
    position: relative;
    background-color: #fff;
    border-radius: size("border-radius");
  }

  @media (min-width: breakpoint("tiny")) {

  }
}

.cms {
  padding: size("gutter--large");
}