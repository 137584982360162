@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$spacing: size("gutter--large") * 3;
$manufacturerLogoWidth: 180px;
$manufacturerLogoHeight: 108px;

:export {
  small: strip-unit(breakpoint("small"));
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.split {
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: breakpoint("small")) {
    flex-direction: row;
    @include stretch();
    @include clearfix();
  }
}

.breadcrumbs {
  margin-bottom: size("gutter--large");
}

.header {
  &__outer {
    margin-bottom: size("gutter");
    display: block;

    @media (min-width: breakpoint("small")) {
      display: none;
    }
  }

  &__inner {
    display: none;
    margin-bottom: size("gutter");

    @media (min-width: breakpoint("small")) {
      display: block;
    }
  }
}

.top {
  display: flex;
  justify-content: space-between;
  padding-right: size("gutter--large") * 1.5;
  margin-bottom: size("gutter--mini");
}

.name {
  display: block;
  font-weight: 900;
  color: color("text");
  font-size: size("font--huge");
  margin-bottom: 0;
}

.brand {
  display: inline-block;
  color: color("primary");
  font-size: size("font--large");
  font-weight: bold;

  margin-top: size("grid-gutter");
}

.left {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size('grid-gutter'), 2);
    float: left;
    width: 50%;
  }
}

.right {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size('grid-gutter'), 2);
    float: right;
    width: 50%;
    padding-left: $spacing;
  }
}

.qtyPicker,
.addToCart,
.login,
.options,
.options > *  {
  @media (max-width: breakpoint("small") - 1px) {
    width: 100%;
  }
}

.qtyPicker {
  margin-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    width: 135px;
  }
}

.action {
  @media (min-width: breakpoint("small")) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.configurable {
    padding-top: size("gutter--large") * 1.5;
  }
}

.price {
  line-height: font("line-height--heading");

  & > div:first-child {
    font-size: size("font");
    font-weight: bold;
  }

  & > div:last-child:not(:first-child) {
    font-size: size("font");
    color: color("text--muted");
    font-style: italic;
    @media (max-width: breakpoint("small") - 1px) {
      margin-bottom: size("gutter") !important;
    }
  }
}

.addToCart {
  @media (min-width: breakpoint("small") - 1px) {
    padding: size("gutter--small") 0;
    border-bottom: 1px solid color("border--muted");
    border-top: 1px solid color("border--muted");
    margin-bottom: size("gutter--large");
    & button {
      margin-bottom: 0;
    }
  }
}

.addToCart h3 {
  font-family: #{font("body")};
  font-weight: bold;
  font-size: size("font");
  margin-bottom: size("gutter--mini");
}

.description {
  margin-top: size("gutter--large");

  &Heading {
    margin-top: 0 !important;
    margin-bottom: size("gutter");
  }

  &Content {
    color: color("text");

    ul {
      list-style-type: disc;
      list-style-position: inside;
      margin-bottom: size("gutter");
    }

    li + li {
      margin-top: size("gutter--small");
    }
  }

  .manufacturer {
    margin-top: size("gutter--large") * 1.5;

    .logo {
      max-width: $manufacturerLogoWidth;
      max-height: $manufacturerLogoHeight;
      display: inline-block;

      & img {
        max-width: $manufacturerLogoWidth;
        max-height: $manufacturerLogoHeight;
      }
    }
  }
}

.lists {
  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }

  padding-top: size("gutter--large") * 2;

  .relatedList {
    width: 100%;

    @media (min-width: breakpoint("small")) {
      padding: 0 math.div(size('grid-gutter'), 2);
      float: left;
      width: 50%;
    }
  }

  .historyList {
    @media (min-width: breakpoint("small")) {
      padding: 0 math.div(size('grid-gutter'), 2);
      float: right;
      width: 50%;
    }

    @media (max-width: breakpoint("small") - 1px) {
      margin-top: size("gutter--large");
    }
  }
}

.hintWrapper {
  min-height: 100vh;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummyContent {
  .top {
    height: calc(50px + #{size("gutter--small") * 2}); // button height + paddings
  }

  .bottom {
    margin-top: size("gutter--large");
    height: 200px;
  }

  .top, .middle, .bottom {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }
}

.wishlistIcon {
  position: absolute;

  @media (max-width: breakpoint("small") - 1px) {
    top: -8px;
    right: -3px;
  }

  @media (min-width: breakpoint("small")) {
    top: -8px;
    right: -9px;
  }
}
