@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

@keyframes pulse {
  0% { transform: scale(1); }
  40% { transform: scale(1.03); }
  100% { transform: scale(1); }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cardValue {
  display: flex;
  flex-wrap: wrap;

  @include stretch(size("gutter--mini"));

  .col {
    flex: 0 0 33.3333%;
    padding: size("gutter--mini");
    opacity: 0;
    animation: fadeIn transition("duration--organisms") transition("ease-ease") forwards;
  }

  .button {
    width: 100%;
    height: 100%;
    padding: size("gutter") size("gutter--small");
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #fff;

    border: 1px solid color("border");

    transition: transition("duration--molecules") opacity transition("ease-in");

    &:hover:not(.active) {
      opacity: 0.7;
    }

    &.active {
      color: color("primary");
      border-color: color("primary");
      animation: pulse transition("duration--organisms") transition("ease-ease") forwards;
    }

    &.outOfStock {
      color: color("text--muted");
    }

    border-radius: size("border-radius");

    @media (max-width: breakpoint("small") - 1px) {
      font-size: size("font--small");
    }
  }

  .outOfStockLabel {
    position: absolute;
    bottom: 0;
    right: -0;

    z-index: 1;

    font-size: size("font--small");
    color: color("text--muted");
    padding: math.div(size("gutter--mini"), 2);
  }
}

.color {
  $size: 32px;

  width: $size;
  height: $size;
  border-radius: 50%;;
  background: #eee;

  margin-bottom: size("gutter--small");
}
