@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$height: 1rem + (size("input-padding") * 2);
$inset: 1rem;

:export {
  textColor: color("text");
  colorMuted: color("text--muted");
}

.block {
}

.label {
  display: block;
}

.button {
  display: block;
  width: 100%;
  margin-top: size("gutter");
}

:global {
  .StripeElement {
    @extend %resetInput;
    display: block;
    width: 100%;
    font-size: max(16px * strip-unit(math.div(font("size"), 100)), 16px);
    margin-top: math.div(size("gutter--mini"), 2);
    padding-left:  $inset;
    padding-right: $inset;
    letter-spacing: inherit;
    background-color: #fff;
    // transition: $transition;
    font-weight: font("weight--bold");
    color: color("text--dark");
    border: 1px solid color("border");
    border-radius: size("border-radius");

    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  .ElementsApp.Input {
    font-family: inherit !important;
  }

  .ElementsApp .InputElement.is-invalid {
    color: color("negative");
  }

  .apple-pay-button {
    height: 55px;
  }

  @supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
      display: inline-block;
      -webkit-appearance: -apple-pay-button;
    }

    .apple-pay-button-black {
      -apple-pay-button-style: black;
    }

    .apple-pay-button-white {
      -apple-pay-button-style: white;
    }

    .apple-pay-button-white-with-line {
      -apple-pay-button-style: white-outline;
    }
  }

  @supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
      display: inline-block;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: 5px;
      padding: 0px;
      box-sizing: border-box;
      min-width: 200px;
      min-height: 32px;
      max-height: 64px;
    }
    .apple-pay-button-black {
      background-image: -webkit-named-image(apple-pay-logo-white);
      background-color: black;
    }
    .apple-pay-button-white {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
    }
    .apple-pay-button-white-with-line {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
      border: .5px solid black;
    }
  }
}

.row {
  & + & {
    margin-top: math.div(size("gutter"), 2);
  }
}

.split {
  @include stretch();
  @include clearfix();
}

.left, .right {
  width: 50%;
  float: left;
  padding: 0 math.div(size("grid-gutter"), 2);
}

.error {
  margin: size("gutter--small") 0;
  margin-bottom: 0;
  color: color("negative");
}
