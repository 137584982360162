@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: color("body");

  &__left, &__right {
    display: flex;
    align-items: center;
  }

  &__left {
    flex: 1;

    .item {
      padding-right: math.div(size("gutter"), 2);

      &:not(:first-child) {
        padding-left: math.div(size("gutter"), 2);
      }
    }
  }

  &__right {
    .item {
      padding-left: math.div(size("gutter"), 2);
    }
  }
}

.item {
  display: flex;
  align-items: center;
  font-size: size("font--small");
}

.price {
  display: none;
  color: color("text--muted");

  @media (min-width: breakpoint("medium")) {
    flex: 1;
    display: flex;
  }

  & > span {
    white-space: nowrap;
    align-self: center;

    &:first-child {
      margin-right: size("gutter--large");
    }

    &:last-child {
      width: 25ch;
      margin-left: size("gutter--large");
    }
  }

  .slider {
    flex: 1;
    width: auto !important;
  }
}

.num_results {
  display: none;

  @media (min-width: breakpoint("small")) {
    display: flex;
  }
}
