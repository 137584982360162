@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.body {
  padding: size("gutter") 0;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.subheader {
  color: color("font");
  font-size: size("font");
}

.reset_button {
  color: color("text--muted");
}

.price_slider {
  padding: size("gutter") 8px;

  &__labels {
    display: flex;
    justify-content: space-between;
    color: color("text--muted");
  }
}

.price_inputs {
  padding-top: size("gutter--large");
  display: flex;
  align-items: center;

  & > div {
    flex: 1;

    &:first-child {
      padding-right: math.div(size("gutter"), 2);
    }

    &:last-child {
      padding-left: math.div(size("gutter"), 2);
    }
  }

  .dash {
    flex-grow: 0;
    flex-shrink: 0;
    align-self: flex-end;
    padding-bottom: .8rem;
  }
}
