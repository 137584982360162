@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$icon-size: 3.3rem;

.block {
  margin-top: size("gutter--large");

  display: flex;
  align-items: center;
}

.icon {
  flex: 0 1 auto;
  width: $icon-size;
  height: $icon-size;
  margin-right: size("gutter");
}

.text {
  flex: 1 0 auto;

  a {
    display: inline-block;
    margin-top: size("gutter--mini");

    text-transform: uppercase;
    letter-spacing: font("uc-letter-spacing");
    font-weight: bold;
  }
}
