@import "../../node_modules/@crossroads/ui-components/src/ui-components/Dropdown.scss";
@import "theme/ui-components/_variables.scss";

$item-height: 42px;

.block {
  width: 100%;

  .head:disabled {
    background: color("body--off");
    border: 2px solid color("border--disabled");
    opacity: 1;
    color: color("text", 30%) !important;

    .headInner {
      color: color("text", 30%) !important;
    }
  }
}

.head {
  // input height
  height: 42px;
}

.head, .body {
  border: 2px solid #757575;
}

.headInner {
  border-right: 0;
}

.blockOpen .body {
  border-top: 2px solid #757575;
}

.chevronArea {
  width: $item-height;
  justify-content: center;
  padding: 0;
}

.headInner, .item, .chevronArea {
  display: flex;
  align-items: center;

  height: $item-height;
  padding-top: 0;
  padding-bottom: 0;
}

.item {
  height: 44px;
  color: color("text");

  &.active {
    background-color: inherit;

    &:after {
      content: "";
      position: absolute;
      right: size("gutter--mini");
      width: 20px;
      height: 20px;
      background: url("/icons/tick.svg") no-repeat center;
      background-size: 100%;
    }
  }

  &:hover {
    text-decoration: underline;
    background: color("body--off");
  }
}

.hasVariant {
  &.dropdownSort {
    &.block {
      width: 150px;
      border: none;
    }

    .head {
      border: none;
      height: 23px;

      &Inner {
        height: 100%;
        font-size: size("font--small");
      }
    }

    .chevronArea {
      width: 23px;
      height: auto;
    }

    .body {
      border: 1px solid lightgrey;
      border-radius: size("border-radius");
      font-size: size("font--small");
    }

    .item {
      font-size: size("font--small");

      &.active {
        &:after {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
