@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  width: 100%;

  .heading {
    font-size: size("font--large");
    text-transform: uppercase;
  }
}

.body {
  display: flex;
  color: color("warning");
  border: 1px solid color("warning");
  background-color: color("warning--background");
  border-radius: 5px;
  padding: size("grid-gutter");
}

.iconWrapper {
  display: flex;
  flex: 1 0 auto;
  margin-right: size("grid-gutter");

  & > svg {
    margin-top: math.div(size("grid-gutter"), 3);
    width: 16px;
    height: 16px;
  }
}