@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$iframeHeight: 550px; // 671px
$iframeWidth: 450px;
$spinnerContainerSideLength: 60px;
$spinnerContainerBG: #fff;
$paddingContainerSmall: size("gutter");

.block {
  display: flex;
  position: fixed;
  z-index: z-index("overlay");
  background: #00000075;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

div.block {
  padding: 0px;
  margin: 0px;

  & > div, & > div > div {
    background-color: transparent !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  .container {
    position: relative;
    height: calc(100vh - #{$paddingContainerSmall * 2});
    width: calc(100vw - #{$paddingContainerSmall * 2});
    background-color: $spinnerContainerBG;
    border-radius: size("border-radius");
    overflow: hidden;

    @media (min-width: breakpoint("tiny")) {
      height: auto;
      width: $iframeWidth;
      min-height: $iframeHeight;
    }
  }

  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-width: 0px !important;
    z-index: 1;
  }

  .spinnerContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: breakpoint("tiny")) {
      min-height: $iframeHeight;
    }
  }
}
