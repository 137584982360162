@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$row-padding: size("gutter--large") * 1.2;
$item-padding: size("gutter");
$itemsMarginTopLarge: 0.8rem;

.block {
  background: color("OOC--color");
  color: #fff;
  overflow-y: scroll;
  max-height: calc(100vh - #{size("user-header-height")});
  overscroll-behavior: contain;

  @media (min-width: breakpoint("small")) {
    max-height: calc(100vh - #{size("header-height") + size("user-header-height")});
  }
}

.topWrapper {
  @media (max-width: breakpoint("small") - 1px) {
    border-top: 1px solid color("text--muted");
  }

  @media (min-width: breakpoint("small")) {
    border-bottom: 1px solid color("text--muted");
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: size("header-height--small");

  @media (max-width: breakpoint("small") - 1px) {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid color("text--muted");
  }

  @media (min-width: breakpoint("small")) {
    height: 6rem;

    .heading {
      color: color("text--border");
    }
  }
}

.back {
  border: none;
  background: none;
  color: #fff;
  padding: 0 0 0 size("gutter--small");

  svg {
    width: size("mobile-header-icon");
    height: size("mobile-header-icon");
  }

  @media (max-width: breakpoint("small") - 1px) {
    padding: 0;
    width: size("header-height--small");
    height: size("header-height--small");
  }

  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

.row {
  width: 100%;

  & + & {
    border-top: 1px solid color("text--muted");
  }

  &:last-child {
    border-bottom: 1px solid color("text--muted");
  }
}

.wrapper {
  display: flex;
  padding-bottom: size("gutter");

  @media (max-width: breakpoint("small") - 1px) {
    padding: 0;
    flex-direction: column;
  }
}

.items {
  @media (min-width: breakpoint("small")) {
    max-height: none !important;
    width: 80% !important;
    margin-top: $itemsMarginTopLarge;

    &>div {
      display: flex;
      flex-wrap: wrap;
      margin: $row-padding - $item-padding;
    }

  }
}

.item {
  @media (max-width: breakpoint("small") - 1px) {
    display: block;
    background: color("border--muted");
    color: color("text");
    & + & {
      border-top: 1px solid color("border--muted", -20%);
    }
  }

  @media (min-width: breakpoint("small")) {
    width: 20%;
    margin: $item-padding size("gutter--mini");
  }

  font-size: size("font--small");
  color: #fff;

  &__heading {
    @media (max-width: breakpoint("small") - 1px) {
      padding: $item-padding;
    }
  }
}

.header {
  color: #fff;

  &:first-child {
    margin-top: size("gutter");
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: $row-padding $row-padding $row-padding 0;
  font-weight: bold;
  color: #fff;
  width: 20%;

  @media (min-width: breakpoint("small")) {
    font-size: size("font--large");
    line-height: font("line-height--heading");

    &:first-child {
      font-size: size("h2");
      text-transform: uppercase;
    }
  }

  @media (max-width: breakpoint("small")) {
    align-items: center;
    padding: $item-padding;
    flex-grow: 1;
  }

  &__large {
    @media (max-width: breakpoint("small") - 1px) {
      display: none;
    }
  }

  &__small {
    @media (min-width: breakpoint("small")) {
      display: none;
    }
  }
}

.chevron {
  transition: transform transition("duration--atoms") transition("ease-in");
  transform: rotate(-90deg) scaleX(1);
  width: size("mobile-header-icon");
  height: size("mobile-header-icon");

  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

button.actionButton {
  @media (max-width: breakpoint("small") - 1px) {
    height: size("header-height--small");
    width: size("header-height--small");
    border-left: 1px solid color("text--muted");
  }
}

.open .chevron {
  transform: rotate(-90deg) scaleX(-1);
}

.close {
  display: flex;
  align-items: center;
  padding: 0 size("gutter") !important;

  @include resetInput;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    color: #fff;
  }

  @media (max-width: breakpoint("small") - 1px) {
    width: size("header-height--small");

    svg {
      width: size("mobile-header-icon");
      height: size("mobile-header-icon");
    }
  }
}

.parent {
  @media (max-width: breakpoint("small") - 1px) {
    color: #fff;
    .heading {
      font-weight: normal;
      font-size: size("font");
    }
  }
  &__item {
    display: flex;
    @media (max-width: breakpoint("small") - 1px) {
      height: size("header-height--small");
    }
  }
}
