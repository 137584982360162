@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {

}

.row {
  &:not(:first-child) { margin-top: size("gutter"); }
  &:not(:last-child)  { margin-bottom: size("gutter"); }
}


.deliveryInfo {
  margin-top: size("gutter--large");

  h2 {
    font-size: size("font");
    text-transform: uppercase;
    margin-bottom: size("gutter--mini");
  }

  p {
    margin-top: 0;
    white-space: pre-line;
  }
}

.customerService {
  display: flex;
  justify-content: center;
  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

.verify {
  width: 100%;
}

.verified {
  padding: 0 size("gutter");

  svg {
    margin-right: size("gutter--small");
  }
}

.description {
  font-size: size("font--small");
  margin: size("gutter--mini") size("gutter--medium");
  text-align: center;
}
