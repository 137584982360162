@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$maxWidth: 350px;

@keyframes enter {
  0%   { opacity: 0; transform: translateX(100%); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes leave {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

.block {
  position: absolute;
  right: 0;
  top: size("gutter--small");
  min-width: 200px;

  backface-visibility: hidden;

  @media (max-width: breakpoints("tiny")) {
    width: 100%;
    left: 0;
  }
  pointer-events: none;

  z-index: z-index("overlay");
}

.wrapper {
  @media (max-width: breakpoints("tiny")) {
    width: 100%;
    padding: 0;
  }
}

.list {
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (min-width: breakpoints("tiny")) {
    top: size("gutter");
    right: size("gutter--large");
    align-items: flex-end;
  }
}



.message {
  padding-bottom: size("gutter--mini");
  pointer-events: auto;
  max-width: $maxWidth;

  &.deleting {
    animation: leave transition("duration--templates") transition("ease-in") forwards;
  }
}

.itemInner {
  animation: enter transition("duration--templates") transition("ease-in") forwards;

  background: #fff;
  padding: size("gutter--small");
  cursor: pointer;

  p {
    margin-top: 0;
    max-width: 90%;
  }

  .success & {
    background: color("positive");
    color: #fff;
  }

  .error & {
    background: color("negative");
    color: #fff;
  }
}

.icon {
  width: 17px;
  height: 17px;
  top: -2px;
  left: -2px;
  float: right;
}
