@use "sass:math";

@import "theme/ui-components/_variables.scss";

$content-margin: size("gutter--section");
$content-margin--medium: math.div(size("gutter--section"), 2);
$content-margin--small: size("gutter--large");

.block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  overflow: hidden;
  text-decoration: none !important;

  &:not(.block__wrap) {
    border-radius: size("border-radius");
  }

  &:hover .cta {
    text-decoration: underline;
  }

  img {
    width: 100%;
    max-width: 2000px;
    position: absolute;
  }

  button.cta {
    margin-top: size("font--large");
    text-decoration: none;
  }

  &:not(.block__wrap) .contentWrapper {
    padding-left: size("gutter--large");
    padding-right: size("gutter--large");

    @media (max-width: breakpoint("tiny") - 1px) {
      padding-left: size("gutter--small");
      padding-right: size("gutter--small");
    }
  }
}

.image {
  display: flex;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.contentWrapper {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  padding-top: size("gutter--large");
  padding-bottom: size("gutter--large");

  width: 100%;
  text-align: center;
}

.children {
  text-transform: uppercase;
  font-weight: bold;
}


.smallImage, .mediumImage, .largeImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  display: none;
}

.smallImage {
  @media (max-width: 440px) {
    display: block;
  }
}

.mediumImage {
  @media (min-width: 441px) and (max-width: 800px) {
    display: block;
  }
}

.largeImage {
  @media (min-width: 801px) {
    display: block;
  }
}
