@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  // extra space in bottom to make up for bottom menu
  margin-bottom: size("gutter--large") * 3;

  @media (max-width: breakpoint("medium")) {
    margin-bottom: size("gutter--large") * 2;
  }
}

.header {
  margin-bottom: size("gutter--large") * 1.5;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: breakpoint("small") - 1px) {
    display: none;
  }
}

.heading {
  margin: 0;
  margin-bottom: size("gutter");
}

.customerService div {
  margin-top: 0;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: z-index("system");

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #fff;

  transition: transform transition("duration--organisms") transition("ease-ease");
}

.steps {
  flex: 1 0 auto;
  display: flex;
}

.step {
  flex: 1 0 auto;
  height: 100%;
  border-bottom: 3px solid #fff;
  color: color("text--muted") !important;
  padding: size("gutter--small");

  user-select: none;

  @media (max-width: breakpoint("tiny")) {
    padding: size("gutter--small") size("gutter--mini");
    font-size: size("font--small");
  }

  & + & {
    margin-left: size("gutter--small");

    @media (max-width: breakpoint("tiny")) {
      margin-left: size("gutter--mini");
    }
  }

  &.active:not(.disabled) {
    border-color: color("primary");
    color: color("primary") !important;
    font-weight: bold;
  }

  &.disabled {
    cursor: not-allowed;
    &:hover {
      text-decoration: none;
    }
    &:active {
      opacity: 1 !important;
    }
  }
}
