@use "sass:math";

@import "theme/ui-components/_variables.scss";

$headerHeight: size("header-height");
$miniCartWidth: 400px;

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.3; }
}

@keyframes slideDown {
  0% { opacity: .7; transform: translateY(0); }
  100% { opacity: 1; transform: translateY($headerHeight); }
}

@keyframes fadeInBackButton {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.container {
  position: relative;

  @media (max-width: breakpoint("small") - 1px) {
    display: none;
  }
}

.block {
  z-index: z-index("system") + 2;
  background: #fff;
  height: size("header-height");
  position: absolute;
  width: 100%;
  display: flex;
  top: size("user-header-height");
  transition: top transition("duration--organisms") transition("ease-ease");
  will-change: top;
  border-bottom: 1px solid color("border--muted");

  &.active {
    box-shadow: -1px 3px 15px 0 rgba(141,151,166,.16);
  }

  &.hidden {
    top: -#{size("header-height") - size("user-header-height")};
  }
}

.wrapper {
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.nav {
  display: flex;
  align-items: stretch;

  svg {
    width: 42px;
    height: 42px;
  }
}

.login {
  margin-left: size("gutter--large");
}

.item {
  font-size: size("font--small");
  font-weight: 700;
  color: color("text");
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  user-select: none;

  display: flex;
  align-items: center;

  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;

  top: 1px; // offset 1px because of uneven font

  transition: border-color transition("duration--atoms") transition("ease-out");

  &:hover:not(.item__logo) {
    text-decoration: none;
    color: color("top");
  }

  & + & {
    margin-left: size("grid-gutter") * 4;
  }

  svg + span {
    margin-left: size("gutter--mini");
  }
}

.back {
  animation: fadeInBackButton transition("duration--templates") transition("ease-out") forwards;
}

.back, .timeoutStep {
  text-transform: uppercase;
  font-weight: 700;
}

.search, .categories, .brands {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  z-index: z-index("system") + 1;
  animation:  slideDown transition("duration--organisms") transition("ease-out") forwards;
}

.categories, .brands {
  background: color("secondary");
}

.dim {
  position: fixed;
  top: size("header-height");
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system");
  background: #000;

  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
}

.pointsShop {
  text-transform: uppercase;
  font-weight: bold;
}

.doubleArrow {
  svg {
    width: 22px !important;
    height: 22px !important;
    stroke-width: .5;
    stroke: #fff;
  }

  > :first-child {
    margin-right: -16px;
    margin-left: -5px;
  }

  > :last-child {
    margin-right: 0;
  }
}

.statusbar {
  z-index: z-index("overlay");
  font-size: size("font--small");

  &User {
    margin-left: size("gutter");

    background: color("body--off");

    display: flex;
    align-items: center;

    .statusbarItem, .statusbarTextItem {
      padding: 0 size("gutter--large");;
    }

    & .statusbarItem, & .statusbarTextItem {
      color: #000;
    }
  }

  &Item, &TextItem {
    height: size("user-header-height");
    display: flex;
    align-items: center;
  }

  &TextItem {
    @media (max-width: breakpoint("medium-large")) {
      display: none;
    }
  }

  &Item {
    cursor: pointer;

    &, & > a {
      color: #fff;
    }

    &.backToContainer {
      @media (max-width: breakpoint("medium-large")) {
        display: none;
      }

      .backTo {
        svg {
          margin-left: size("grid-gutter");
        }
      }
    }

    &:hover {
      text-decoration: underline;
    }

    span {
      height: 100%;
      display: flex;
      align-items: center;
    }

    svg {
      width: 15px;
      height: 15px;

      margin-right: size("gutter--mini");
    }

    & + & {
      margin-left: size("gutter--small");
    }
  }

  &Separator {
    height: math.div(size("user-header-height"), 2);
    border-left: 1px solid #000;
  }

  &Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
    }
  }
}

.scandicfriends {
  font-weight: bold;
  text-transform: uppercase;
  margin-right: size("gutter--small");

  &:hover {
    text-decoration: none;
    cursor: default;
  }
}

.languageChevron {
  transform: rotate(-90deg);
  margin-left: size("gutter--mini");
}

.languageSwitcher {
  position: absolute;
  top: size("user-header-height");
  left: 0;
  overflow: hidden;
  border-bottom-left-radius: size("border-radius");
  border-bottom-right-radius: size("border-radius");
  font-size: size("font");

  min-width: 200px;

  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.2);
}

.cartMini {
  position: absolute;
  top: size("header-height");
  left: auto;
  right: size("gutter--large");
  width: $miniCartWidth;
  z-index: z-index("overlay");
  box-shadow: 0 24px 64px 0 rgba(0, 0, 0, 0.1);
  border-radius: size("border-radius");
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

$checkout-timeout-timer: 3000ms;

@keyframes loadAnimation {
  0% { width: 00%; }
  65% { width: 40%; }
  85% { width: 90%; }
  100% { width: 100%; }
}

.loader {
  position: absolute;
  top: size("header-height") + size("user-header-height");
  left: 0;
  right: 0;
  height: 6px;
  background: color("primary");
  transform-origin: 0 0;
  transition: width transition("duration--atoms") transition("ease-out");
  animation: loadAnimation $checkout-timeout-timer transition("ease-in") forwards;
}

@keyframes timeoutStepAppear {
  0% { opacity: 0; transform: translateY(17px); }
  100% { opacity: 1; transform: translateY(0); }
}

.timeoutStep {
  animation: timeoutStepAppear transition("duration--templates") transition("ease-in") forwards;
}
