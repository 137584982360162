@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.item {
  @media (min-width: breakpoint("medium")) {
    display: flex;
    justify-content: space-between;
  }

  background: #fff;
  padding: size("gutter");
  font-size: size("font--small");
  border-radius: size("border-radius");
  transition: opacity transition("duration--atoms") transition("ease-in");

  &.processing {
    opacity: 0.6;
  }

  &.disabled {
    pointer-events: none;
  }

  & + & {
    margin-top: size("gutter--large");
  }


  @media (max-width: breakpoint("medium") - 1px) {
    padding-bottom: size("gutter") * 1.1;
  }
}

.left {
  display: flex;

  @media (min-width: breakpoint("medium")) {
    flex: 1 1 auto;
    justify-content: flex-start;
    margin-right: size("gutter");
  }
}

.right {
  @media (min-width: breakpoint("medium")) {
    flex: 1 1 auto;
    text-align: right;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
  }

  .remove {
    color: color("negative") !important;

    @media (max-width: breakpoint("medium") - 1px) {
      left: -5px;
    }
  }

  .top {
    display: flex;

    @media (max-width: breakpoint("medium") - 1px) {
      margin-top: size("gutter--small") * 2;
    }
  }

  .bottom {
    margin-top: size("gutter") * 1.2;
    display: flex;
  }
}

.image {
  width: 80px;
  height: 80px;
  border-radius: math.div(size("border-radius"), 2);
  margin-right: size("gutter--small");

  @media (max-width: breakpoint("medium") - 1px) {
    float: left;
  }
}

.info {
}

.name {
  color: color("text");
  font-weight: bold;
  line-height: 1.2;
  margin: 0;

  @media (min-width: breakpoint("medium")) {
    width: 240px;
  }
}

.brand {
  color: color("text--muted");
  margin: 0;
}

.links {
  @media (max-width: breakpoint("medium") - 1px) {
    left: size("gutter");
    bottom: size("gutter");
  }
}

.link {
  & + & {
    margin-left: size("gutter");
  }
}

.price {
  line-height: font("line-height--heading");
  margin-left: size("gutter--small");

  & > div:first-child {
    font-size: size("font");
    font-weight: bold;
  }

  & > div:last-child:not(:first-child) {
    font-size: size("font");
    color: color("text--muted");
    font-style: italic;
    @media (max-width: breakpoint("small") - 1px) {
      margin-bottom: size("gutter") !important;
    }
  }
}

.cash {
  display: block;
  margin-top: size("gutter--mini");
  font-weight: 400;
  color: color("text--muted");
  font-size: size("font--small");
}

.qtyPicker {
}

.bundleOptions {
  margin-top: size("gutter--mini");
  font-size: size("font--small");
  color: color("text--muted");
  list-style-type: disc;
  list-style-position: inside;
}

.confirmDialogue {
  width: 500px;
  padding: size("gutter--medium");

  .closeIconWrapper {
    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  button:first-child {
    color: color("text--muted");
  }

  button:last-child {
    color: color("secondary");
  }

  button {
    text-transform: uppercase;
    font-size: size("font");
  }
}
