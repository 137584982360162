@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  color: color("warning");

  .list {
    li {
      display: flex;

      svg {
        flex: 0 0 auto;
        width: 18px;
        height: 18px;
        margin-top: 4px;
        margin-right: 5px;

        @media (min-width: breakpoint("tiny-small")) {
          width: 36px;
          height: 36px;
          margin-right: 10px;
        }
      }

      p {
        margin: 0px;
      }
    }

    li + li {
      margin-top: size("gutter");
    }
  }
}
