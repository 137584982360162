@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.bottom {
  width: 100%;
  flex: 1 0 auto;
  color: #fff;
  padding-top: size("gutter--large") * 1.5;
  padding-bottom: size("gutter--large") * 1.5;

  .row {
    display: flex;
    align-content: center;
    justify-content: space-between;

    &:not(:last-child) {
      padding-bottom: size("gutter--large");
    }

    & > div:last-child {
      text-align: right;
    }
  }

  .link {
    cursor: pointer;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }
}