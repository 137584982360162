@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$vertical-margin: size("gutter--large") * 2;

.block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include stretch(size("gutter"));
}

.item {
  flex: 1 1 100%;
  min-width: 0;
  padding: 0 size("gutter");
  margin-top: $vertical-margin;

  .block__perRow4 & {
    @media (min-width: breakpoint("tiny")) {
      flex: 0 0 50%;

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }
    }

    @media (min-width: breakpoint("small")) {
      flex: 0 0 33.33%;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0;
      }
    }

    @media (min-width: breakpoint("medium")) {
      flex: 0 0 25%;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 0;
      }
    }
  }

  .block__perRow2 & {
    @media (min-width: breakpoint("medium")) {
      flex: 0 0 100%;
      margin-top: 0;
    }

    @media (min-width: breakpoint("tiny")) {
      flex: 0 0 50%;

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
}

.itemInner {
  height: 100%;
}
