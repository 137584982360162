@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.value {
  background-color: color("body");
  padding: size("gutter") 0;
  border-top: 1px solid color("border--muted");
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  &:first-child {
    border-top: none;
  }

  .checkmark {
    fill: color("primary");
  }
}
