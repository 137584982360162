@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.heading {
  font-size: size("font--large");
  text-transform: uppercase;
}

.list {
  width: 100%;
}

.muted {
  color: color("text--muted");
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  padding: size("gutter");
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid color("border--muted");
  border-radius: size("border-radius");
  cursor: pointer;
  transition: border transition("duration--atoms") transition("ease-ease");

  &.empty {
    font-size: size("font--small");
    border-color: color("border--muted");
    cursor: inherit;
    &:hover {
      border-color: color("border--muted");
    }
    p {
      margin-bottom: 0;
    }
  }

  &:hover {
    border-color: color("border");

    button {
      text-decoration: underline;
    }
  }

  & + & {
    margin-top: size("gutter");
  }

  .info {
    span {
      display: block;
      line-height: 1.33;
    }

    .id {
      font-weight: bold;
      font-size: size("font--mini");
    }

    .date {
      font-size: size("font--mini");
      color: color("text--muted");
    }
  }

  .open {
    color: color("primary");
    font-size: size("font--mini");

    &:hover {
      text-decoration: underline;
    }
  }
}


.dialogue {
  margin: -.3rem 0;

  .header {
    background-color: #fff;
    padding: size("gutter--mini") size("gutter--large");
    padding-right: size("gutter");
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      h4, p {
        margin: 0;
        padding: 0;
        font-size: size("font--mini");
        line-height: 1.33;
      }

      h4 {
        font-weight: bold;
      }
    }

    .close_button {
      height: 36px;
      width: 36px;

      & > svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .content {
    background-color: color("body--off");
    padding: size("gutter--large");
    width: 100%;

    table {
      width: 100%;
      font-size: size("font--mini");
      line-height: 1.33;
    }

    table + table {
      margin-top: size("gutter--medium") !important;
    }

    tr {
      min-height: 16px;

      &:not(:first-child) {
        min-height: 19px;
      }

      & > td:first-child {
        font-weight: bold;
      }
    }

    td {
      padding-top: 0 !important;
      vertical-align: top;
    }

    td + td {
      text-align: end;
    }

    .product > td {
      font-weight: normal !important;
    }

    .title {
      white-space: nowrap;
    }

    .address {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      & > span {
        padding-left: size("gutter--mini");
      }
    }
  }
}
