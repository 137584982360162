@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$leftBlockWidthMedium: 310px;

.block {
  margin-top: -40px;

  .mainSplit {
    margin-top: size("gutter--large") * 1.7;
    display: flex;
    flex-direction: column;

    @media (min-width: breakpoint("tiny-small")) {
      flex-direction: row;
      margin-top: size("gutter--large");
    }

    @media (min-width: breakpoint("medium")) {
      margin-top: size("gutter--large") * 1.7;
    }

    .left {
      @media (max-width: breakpoint("tiny-small") - 1px) {
        .elapsing_points {
          margin-top: size("gutter--large") * 1.7;
        }
      }

      @media (min-width: breakpoint("tiny-small")) {
        width: $leftBlockWidthMedium;
        padding-right: size("gutter--small");

        .shipping_address {
          margin-bottom: size("gutter--large");
        }
      }
    }

    .right {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      .history, .wishlist {
        margin-top: size("gutter--large") * 1.7;
      }

      @media (min-width: breakpoint("tiny-small")) {
        padding-left: size("gutter--small");

        .history {
          margin-top: 0;
        }

        .wishlist {
          margin-top: size("gutter--large");
        }
      }

      @media (min-width: breakpoint("medium")) {
        flex-direction: row;

        .history, .wishlist {
          flex: 0 0 50%;
        }

        .history {
          padding-right: size("gutter--small")
        }

        .wishlist {
          margin-top: 0;
          padding-left: size("gutter--small");
        }
      }
    }
  }
}



