@import "theme/ui-components/_variables.scss";

$size: 45px;
$icon-size: 12px;
$min-width: 135px;
$max-width: 180px;

$border-color: color("disabled");

.block {
  height: $size;
  display: flex;
  min-width: $min-width;
  max-width: $max-width;
}

.increment, .decrement, .input {
  border: 1px solid $border-color;
  background: #fff;
  padding: size("gutter--small") 0;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.increment, .decrement {
  border-radius: 6px;
  padding: size("gutter--small");
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  color: color("text");

  svg {
    width: $icon-size;
    height: $icon-size;
  }

  &:disabled, &:disabled:hover {
    background: $border-color;
    border-color: $border-color;
  }
  &:hover {
    background: color("background--hover");
  }
  &:active {
    opacity: .5;
  }
}

.increment {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border: 1px solid $border-color;

  border-radius: 0;
  border-left: 0;
  border-right: 0;

  width: $size;

  flex: 0 0 auto;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.decrement {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
