@import "../../node_modules/@crossroads/ui-components/src/ui-components/Button.scss";

$border-radius: 30px;
$button-height--medium: 44px;

.button {
  border: none;

  &.primary {
    transition: background 0.1s;
    font-size: size("font");
    border-radius: $border-radius;

    &:enabled:hover {
      background: color("primary");
      text-decoration: underline;
    }
  }

  &.secondary {
    @include commonButton(
      $background: color("secondary"),
      $color: #fff,
      $br: size("border-radius"),
    );

    transition: background 0.1s;
    font-size: size("font");
    border-radius: $border-radius;
  }

  &.secondarydark {
    @include commonButton(
      $background: #486fe3,
      $color: #fff,
      $br: size("border-radius"),
    );

    transition: background 0.1s;
    font-size: size("font");
  }

  &.black {
    font-size: size("font");
  }

  &.blank {
    padding: size("gutter--mini") * 1.1 size("gutter--large");
    color: color("primary");

    &:enabled:active:hover {
      text-decoration: underline;
    }
  }

  &.negative {
    @include commonButton(
      $background: #fff,
      $color: color("primary"),
      $br: size("border-radius"),
    );

    transition: background 0.1s;
    border-radius: $border-radius;

    &:enabled {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.ghost {
    font-size: size("font");
    border-radius: $border-radius;
  }
}

.hasVariant.size {
  &__large {
    padding: size("gutter--mini") * 1.17 size("gutter--large") size("gutter--mini") * 1.1;

  }

  &__medium {
    height: $button-height--medium;
    padding: 0 size("gutter");
  }

  &__small {
    padding: size("gutter--mini") * .65 size("gutter--small") size("gutter--mini") * .5;
    font-size: size("font");
  }
}

