@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  .boxHeader {
    border: 0;
    text-transform: none;

    label {
      cursor: pointer;
    }

    & + main {
      border-top: 1px solid color("border--muted");
    }
  }

  .discountCode {
    display: flex;
    align-items: flex-end;

    & > :first-child {
      flex: 1 0 auto;
      margin-right: size("gutter");
    }
  }
}
