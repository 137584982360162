@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/ui-components/src/ui-components/AppFooter";

.footer {
  background-color: #111;
  color: color("body");

  h2,
  .heading {
    color: color("body");
    margin-bottom: size("gutter--small");
  }

  .heading {
    margin-bottom: size("gutter--small");
    font-weight: 700;
    text-transform: uppercase;
  }
}

.nav,
.col nav {
  color: color("body");

  a, 
  .link {
    color: color("body");
    padding: size("grid-gutter") 0;
  }
}

.cols > div {
  @media (min-width: (breakpoint("tiny"))) and (max-width: (breakpoint("small") - 1px)) {
    &:nth-child(2) {
      text-align: end;
      nav {
        align-items: flex-end;
      }
    }
  }
}

.contentTerms {
  * {
    color: color("body");
    font-size: size("font--small");
  }

  p {
    margin: 0;
  }

  a, 
  button,
  button > span {
    color: color("tooltip--background");
  }
}