@import "theme/ui-components/_variables.scss";

$size: 30px;
$icon-size: 6px;
$min-width: 125px;
$max-width: 170px;

$border-color: color("disabled");

.block {
  height: $size;
  display: inline-flex;
  justify-content: flex-end;
  min-width: $min-width;
  max-width: $max-width;
  margin-left: size("gutter--small");
}

.increment, .decrement, .input {
  border: none;
  background: #fff;
  color: color("text--muted");
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 1rem;
}

.increment, .decrement {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: $icon-size;
    height: $icon-size;
    margin: size("gutter--mini") - .3rem;
  }

  &:disabled, &:disabled:hover {
    background: $border-color;
  }
  &:hover {
    background: color("background--hover");
  }
  &:active {
    opacity: .5;
  }
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 12px !important;
//   border: 1px solid $border-color;

  border-radius: 0;
  border-left: 0;
  border-right: 0;

  width: $size;

  flex: 0 0 auto;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}