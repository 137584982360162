@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  h2 {
    @media (max-width: breakpoint("mini") - 1px) {
      font-size: size("font");
    }
  }
}

.toggle {
  @media (max-width: breakpoint("tiny") - 1px) {
    padding-right: 0 !important;
    background: transparent !important;
  }
}

.row {
  &:not(:first-child) { margin-top: size("gutter"); }
  &:not(:last-child)  { margin-bottom: size("gutter"); }
}

.minimized {
  &Contact {
    margin-bottom: size("gutter");

    p {
      color: color("text--muted");
      font-size: size("font--small");
      margin: 0px;
    }
  }

  &Info {
    margin-top: 0px;
    color: color("text--muted");
    font-size: size("font--small");
  }
}

.editAddresses {
  background-color: color("body");
}

.error {
  color: color("negative");
}

.addressTitle {
  margin-bottom: 0px;
  font-size: size("font--small");
}
