@use "sass:math";

@import "theme/ui-components/_variables.scss";

.errors {
  color: color("negative");
  font-size: size("font--small");
  padding-top: size("gutter--small");
}

.label {
  display: block;
  color: color("text--muted");
  margin-bottom: math.div(size("gutter--mini"), 2);
}
