@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  animationDuration: strip-unit(transition("duration--organisms"));
}

$height: size("header-height--small");
$combined-height: $height + size("user-header-height");

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.3; }
}

@keyframes fadeOut {
  0% { opacity: 0.3; }
  100% { opacity: 0; }
}

@keyframes drawerIn {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}

@keyframes slideDown {
  0% { opacity: .7; transform: translateY(-100px); }
  100% { opacity: 1; transform: translateY(0); }
}

.outerContainer {
  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

.statusbar {
  display: flex;
  align-items: center;
  justify-content: center;

  height: size("user-header-height");
  font-size: size("font--mini");
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  background: color("OOC--color");

  transition: transform transition("duration--organisms") transition("ease-ease");
  will-change: transform;

  &.hidden {
    transform: translateY(-$combined-height);
  }

  &.hamburgerOpen {
    transform: translateY(0) !important;
    z-index: 5;
  }
}

.container {
  z-index: 1;
  transition: transform transition("duration--organisms") transition("ease-out");
  transform: translateX(0);

  &.hamburgerOpen.subNavOpen {
    transform: translateX(-100%);
  }
}

.block {
  display: flex;
  align-items: stretch;

  z-index: 1;
  background: #fff;
  height: $height;

  position: absolute;
  transition: transform transition("duration--organisms") transition("ease-ease");
  will-change: transform;
  width: 100%;
  border-bottom: 1px solid color("border--muted");

  &.active {
    box-shadow: -1px 3px 15px 0 rgba(141,151,166,.16);
  }

  &.hidden {
    transform: translateY(-$combined-height);
  }
}

.padding {
  padding: size("gutter") 0;
}

.wrapper {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;

  .title {
    font-size: size("font--medium");
    margin: 0;
  }
}

.nav {
  display: flex;
  align-items: center;

  .left & {
    margin-left: size("gutter--large") * 2;
  }
}

.login {
  margin-left: size("gutter--large") !important;

  @media (max-width: breakpoint("tiny")) {
    margin-left: size("gutter--small") !important;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: size("font--small");
  padding-top: 0;
  padding-bottom: 0;

  color: color("text");
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  user-select: none;

  &:hover {
    text-decoration: none;
  }

  & + & {
    margin-left: size("gutter") * 1.2;
  }

  svg + span {
    margin-left: size("gutter--mini");
  }
}

.item svg {
  color: color("text--muted");
}

.item.active svg {
  color: color("primary");
}

.grandTotal {
  color: color("text--muted", 50%);
  font-weight: normal;
  text-transform: none;
  font-size: size("font--small");
}

.cartCount {
  font-weight: normal;
  margin-left: size("gutter--mini");;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: size("gutter--small");
  padding-bottom: size("gutter--small");
  height: size("header-height--small");

  @media (min-width: breakpoint("small")) {
    height: size("header-height");
  }

   svg path {
    fill: #fff;
  }
}

.drawer {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: color("OOC--color");
  border-top: 1px solid color("text--muted");

  height: 100vh;
  overflow-y: auto;

  transition: transform transition("duration--organisms") transition("ease-out");

  transform: translateX(100%);
  opacity: 0;

  .hamburgerOpen & {
    transform: translateX(0%);
    opacity: 1;
  }

  .isOpening & {
    opacity: 1;
  }

  .isClosing & {
    opacity: 1;
    transform: translateX(100%) !important;
  }

  .item {
    margin-left: 0;
    padding: 0 size("gutter");
    height: size("header-height--small");
    border-top: 1px solid color("text--muted");

    &:last-child {
      border-bottom: 1px solid color("text--muted");
    }


    &, svg {
      color: #fff;
    }
  }

  &Main {
    width: 100%;
    flex: 0 0 auto;
  }
}

.close {
  width: size("mobile-header-icon");
  height: size("mobile-header-icon");
}

.nav .item svg {
  width: 36px;
  height: 36px;
}

.categories, .brands, .search, .language {
  background: color("OOC--color");

  position: absolute;
  top: 0px;
  left: 100%;
  right: 0px;
  width: 100%;
  min-height: 100vh;

  display: none;
}

.search {
  background: #fff;
}

.cartMini {
  position: absolute;
  left: 0;
  top: size("header-height--small") !important;
  width: 100%;
  max-height: calc(100vh - #{size("header-height--small") + size("user-header-height")});
  z-index: z-index("system");
  box-shadow: 0 24px 64px 0 rgba(0, 0, 0, 0.1);
  border-radius: size("border-radius");
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dim {
  position: fixed;
  top: size("header-height--small");
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;

  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
}

.mode__categories .categories { display: block; }
.mode__brands .brands { display: block; }
.mode__search .search { display: block; }
.mode__language .language { display: block; }
