@use "sass:math";

@import "../../node_modules/@crossroads/ui-components/src/ui-components/Input.scss";

$transparent-color: #fff;

.block {
  max-width: none;
}

.input {
  border: 2px solid #757575;
  background: $transparent-color;
  padding: 0 size("gutter--mini");
  line-height: 1;
  height: 42px;

  &[disabled] {
    background: color("body--off");
    border: 2px solid color("border--disabled");
  }

  &:focus {
    // border-width: 4px;
    border-color: color("primary") !important;
    box-shadow: inset 0 0 0px 2px color("primary");
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $transparent-color inset;
    -webkit-text-fill-color: color("text");
  }
}

.label {
  color: color("text--muted");
  margin-bottom: math.div(size("gutter--mini"), 2);
}

.error .input {
  border-color: color("negative") !important;
}

.errors {
  color: color("negative");
  margin-top: size("gutter--mini");
}

.float .input:focus + .label,
.float:not(.empty) .label {
  font-weight: 400;
  font-size: size("font--small");
}
