@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  .box {
    background: #fff;
    padding: size("gutter");
    border-radius: size("border-radius");
    border: 1px solid color("border--muted");

    margin-bottom: size("gutter");
  }

  .heading {
    font-size: size("font--large");
    text-transform: uppercase;
  }

  .edit {
    display: flex;
    align-items: center;
  }

  .missingAddress {
    color: color("negative");
  }
}

