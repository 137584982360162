@use "sass:math";

@import "ui-components/_variables.scss";

$triangle-height: 10px;
$triangle-side-height: 7px;
$triangle-width: 13px;
$border-thickness: 1px;
$fade-in-duration: 150ms;
$triangle-size: calc(#{$triangle-height} - (#{$border-thickness} * 2));
$size: 0.7em;
$size-large: 1em;
$z: 100;
$br: 27.5px;
$background-color: color("tooltip--background");

@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.block {
  position: relative;
  display: inline-block;
  text-transform: initial !important;
}

.title {
  color: color("text");
  margin-bottom: 8px;
}

.tooltip {
  position: absolute;
  z-index: $z;
  animation: fadeIn $fade-in-duration linear;

  &Inner {
    padding: 1.5rem;
    border-radius: $br;
    background-color: $background-color;
    color: color("text");
    white-space: nowrap;

    .block__top & {
      bottom: 0;
    }

    .block__bottom & {
      top: 0;
    }

    .block__left & {
      right: 0;
    }

    .block__right & {
      left: 0;
    }

    .block__bottom_left & {
      border-top-left-radius: inherit;
    }

    .block__top_left & {
      border-bottom-left-radius: inherit;
    }

    .block__bottom_right & {
      border-top-right-radius: inherit;
    }

    .block__top_right & {
      border-bottom-right-radius: inherit;
    }
  }

  // center horizontally
  .block__top &, .block__bottom & {
    left: 50%;
    transform: translateX(-50%);
  }

  // center vertically
  .block__left &, .block__right & {
    transform: translateY(-50%);
    top: 50%;
    transform: translateY(-50%);
  }

  .block__top & {
    margin-bottom: $triangle-height;
    bottom: calc(100% + #{math.div($size, 2)});
  }

  .block__bottom & {
    margin-top: $triangle-height;
    top: calc(100% + #{math.div($size, 2)});
  }

  .block__left & {
    margin-right: $triangle-height;
    right: calc(100% + #{$size});
  }

  .block__right & {
    margin-left: $triangle-height;
    left: calc(100% + #{$size});
  }

  .block__bottom_left & {
    top: calc(100% + #{$size-large});
    left: 50%;
    border-top-left-radius: inherit;
  }

  .block__top_left & {
    bottom: calc(100% + #{$size-large});
    left: 50%;
    border-bottom-left-radius: inherit;
  }

  .block__bottom_right & {
    top: calc(100% + #{$size-large});
    right: 50%;
    border-top-right-radius: inherit;
  }

  .block__top_right & {
    bottom: calc(100% + #{$size-large});
    right: 50%;
    border-bottom-right-radius: inherit;
  }

  &::before {
    content: "";
    display: block;
    width:  0;
    height: 0;
    position: absolute;
    border: $triangle-height solid transparent;

    // up arrow
    .block__bottom & {
      border-bottom: $triangle-height solid color("border");
      right: calc(50% - #{$triangle-height});
      bottom: 100%;
    }

    // down arrow
    .block__top & {
      border-top: $triangle-height solid color("border");
      right: calc(50% - #{$triangle-height});
      top: 100%;
    }

    // right arrow
    .block__left & {
      border-left: $triangle-height solid color("border");
      top: calc(50% - #{$triangle-height});
      left: 100%;
    }

    // left arrow
    .block__right & {
      border-right: $triangle-height solid color("border");
      top: calc(50% - #{$triangle-height});
      right: 100%;
    }

    // top-left arrow
    .block__bottom_left & {
      border: $triangle-side-height solid transparent;
      border-bottom: $triangle-side-height solid color("border");
      border-left: $triangle-side-height solid color("border");
      left: 0px;
      bottom: calc(100% - 1px);
    }

    // bottom-left arrow
    .block__top_left & {
      border: $triangle-side-height solid transparent;
      border-top: $triangle-side-height solid $background-color;
      border-left: $triangle-side-height solid $background-color;
      left: 0;
      top: calc(100% - 1px);
    }

    // top-right arrow
    .block__bottom_right & {
      border: $triangle-side-height solid transparent;
      border-bottom: $triangle-side-height solid color("border");
      border-right: $triangle-side-height solid color("border");
      left: calc(100% - #{$triangle-width + 1px});
      bottom: 100%;
    }

    // bottom-right arrow
    .block__top_right & {
      border: $triangle-side-height solid transparent;
      border-top: $triangle-side-height solid color("border");
      border-right: $triangle-side-height solid color("border");
      left: calc(100% - #{$triangle-width + 1px});
      top: calc(100% - 1px);
    }
  }

  &::after {
    content: "";
    display: block;
    width:  0;
    height: 0;
    position: absolute;
    border: $triangle-height solid transparent;
    color: $background-color;

    // up arrow
    .block__bottom & {
      border-bottom: $triangle-height solid;
      right: calc(50% - #{$triangle-height});
      bottom: calc(100% - #{$border-thickness});
    }

    // down arrow
    .block__top & {
      border-top: $triangle-height solid;
      right: calc(50% - #{$triangle-height});
      top: calc(100% - #{$border-thickness});
    }

    // right arrow
    .block__left & {
      border-left: $triangle-height solid;
      top: calc(50% - #{$triangle-height});
      left: calc(100% - #{$border-thickness});
    }

    // left arrow
    .block__right & {
      border-right: $triangle-height solid;
      top: calc(50% - #{$triangle-height});
      right: calc(100% - #{$border-thickness});
    }

    // top-left arrow
    .block__bottom_left & {
      border: $triangle-side-height solid transparent;
      border-bottom: $triangle-side-height solid;
      border-left: $triangle-side-height solid;
      left: 1px;
      bottom: calc(100% - 3px);
    }

    // bottom-left arrow
    .block__top_left & {
      border: $triangle-side-height solid transparent;
      border-top: $triangle-side-height solid;
      border-left: $triangle-side-height solid;
      left: 1px;
      top: calc(100% - 3px);
    }

    // top-right arrow
    .block__bottom_right & {
      border: $triangle-side-height solid transparent;
      border-bottom: $triangle-side-height solid;
      border-right: $triangle-side-height solid;
      left: calc(100% - #{$triangle-width} - 2px);
      bottom: calc(100% - 2px);
    }

    // bottom-right arrow
    .block__top_right & {
      border: $triangle-side-height solid transparent;
      border-top: $triangle-side-height solid;
      border-right: $triangle-side-height solid;
      left: calc(100% - #{$triangle-width} - 2px);
      top: calc(100% - 3px);
    }
  }
}
