@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$animationTimingFunction: transition("ease-ease");
$animationDuration: transition("duration--templates");
$headerHeight: 68px;
$activeFiltersMinHeight: 70px;
$submitButtonHeight: 72px;
$closeButtonSideLength: 36px;
$closeButtonOffsetRight: -8px;
$clearButtonDesktopPaddingY: 4px;

:export {
  animationDuration: strip-unit($animationDuration);
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.3; }
}

.block {
  position: fixed;
  // height: 100vh;
  max-height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  width: 500px;
  z-index: z-index("system") + 2;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  transition: transform  $animationDuration $animationTimingFunction;
  transform: translateX(0);

  overflow-y: auto;

  &.hidden {
    transform: translateX(-100%);
  }

  @media (max-width: #{breakpoint("tiny") - 1px}) {
    width: 100vw;
  }
}

.header {
  flex: 0 0 auto;
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: size("gutter") size("gutter--medium");

  @media (min-width: breakpoint("tiny")) {
    padding: size("gutter") #{size("gutter--medium") + size("gutter--large")};
  }

  & > h4 {
    margin: 0;
  }

  .closeButton {
    width: $closeButtonSideLength;
    height: $closeButtonSideLength;
    right: $closeButtonOffsetRight;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }
}

.body {
  height: calc(100vh - #{$headerHeight});
  max-height: calc(100vh - #{$headerHeight});
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.middle {
  height: calc(100% - #{$submitButtonHeight});
  max-height: calc(100% - #{$submitButtonHeight});
  display: flex;
  flex-direction: column;

  .filters {
    display: flex;
    flex-direction: column;
  }
}


.bottom {
  flex: 0 1 auto;
  height: $submitButtonHeight;

  button.submit_button {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.dim {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system") + 1;
  background: #000;
  opacity: 0.3;
  will-change: opacity transform;

  &.hidden {
    opacity: 0;
    display: none;
  }

  &.hiding {
    animation: dimLeaving $animationDuration $animationTimingFunction forwards;
  }

  &.entering {
    animation: dimEntering $animationDuration $animationTimingFunction forwards;
  }
}

.activeFiltersContainer {
  .clearbutton {
    &Mobile {
      color: #fff;
      background: color("secondary");
      height: $activeFiltersMinHeight;
      width: 100%;
      font-size: size("font--medium-small");
      border-radius: 0;

      @media (min-width: breakpoint("tiny")) {
        display: none;
      }
    }

    &Desktop {
      margin-left: size("gutter--large");
      padding-top: $clearButtonDesktopPaddingY;
      padding-bottom: $clearButtonDesktopPaddingY;
      color: color("secondary");
      display: none;

      @media (min-width: breakpoint("tiny")) {
        display: block;
      }
    }
  }
}

.activeFilters {
  display: flex;
  justify-content: space-between;
  margin: 0 size("gutter--medium");
  padding: size("gutter--mini") 0;
  min-height: $activeFiltersMinHeight;
  border-bottom: 1px solid color("border--muted");

  @media (min-width: breakpoint("tiny")) {
    margin: 0 #{size("gutter--medium") + size("gutter--large")};
    border-top: 1px solid color("border--muted");
  }

  &Left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: - size("gutter--large");
  }

  &Right {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &Empty {
    min-height: $activeFiltersMinHeight;
    background: color("body--off");

    display: flex;
    flex: 0 1 auto;
    align-items: center;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > p {
      margin: 0;
    }
  }
}


.filters {
  flex: 1;
  overflow-y: auto;
  overscroll-behavior: contain;
}

@keyframes dimEntering {
  0% { opacity: 0;

  }
  100% { opacity: 0.3; }
}

@keyframes dimLeaving {
  0% { opacity: 0.3; }

  100% { opacity: 0; }
}
