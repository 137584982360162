@use "sass:math";

@import "theme/ui-components/_variables.scss";

.block {
  svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    path {
      fill: color("top") !important;
    }
  }

  .spinner {
    flex-direction: column-reverse;
    transform: scale(.5);

    span {
      margin-left: 0px;

      &:not(:last-of-type) {
        margin-top: math.div(size("gutter--mini"), 2)
      }
    }
  }
}

.count {
  font-size: 1.1em;
  font-weight: bold;
}
