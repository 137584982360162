@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$icon-size: 2rem;

.block {
  overflow: hidden;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: size("gutter--small");
  background: color("OOC--color");
  height: size("header-height");
  text-transform: uppercase;

  &, & svg {
    color: #fff;
  }

  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

.heading {
  display: block;
  font-size: size("font--medium");
  font-weight: bold;
}

.back {
  border: none;
  background: none;
  padding: 0;

  svg {
    width: size("mobile-header-icon");
    height: size("mobile-header-icon");
  }
}

.close, .submit {
  @include resetInput;
  cursor: pointer;

  @media (max-width: breakpoint("small") - 1px) {
    svg {
      width: size("mobile-header-icon");
      height: size("mobile-header-icon");
    }
  }
}

.container {
  display: flex;
  align-items: center;
  background: white;

  @media (max-width: breakpoint("small") - 1px) {
    border-bottom: 1px solid color("border");
  }
}

.icon {
  flex: 0 1 auto;
  margin-right: size("gutter");
  width: $icon-size;
  height: $icon-size;

  g {
    fill: color("primary");
  }
}

.input {
  flex: 1 1 auto;

  width: 100%;
  padding: size("gutter--large") * 1.5 0;
  border: none;

  font-size: size("font--large") !important;

  background: none;

  cursor: text;

  @include placeholderStyles {
    color: color("text--muted");
  }

  &:focus {
    outline: none;
  }
}

.suggestions {
  background: color("body");

  // offset to collapse with eventual border above
  margin-top: -1px;
}

.suggestion {
  background: color("body");
  border-top: 1px solid color("border");
  padding: size("gutter--small") 0;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Left {
    display: flex;
    align-items: center;
  }

  &Body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: size("gutter");
  }

  &Name {
    margin-bottom: 0;
    font-size: size("font--medium");
  }

  &Brand {
    margin-top: 0;
    color: color("text--muted");
  }

  &Price {
    align-self: flex-start;
  }
}
