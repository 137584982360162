@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$img-size: 64px;

@keyframes appear {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.block {
  opacity: 0;
  animation: appear transition("duration--templates") transition("ease-out") forwards;
  animation-delay: transition("duration--molecules");
  display: flex;
  flex-direction: column;
  background: #fff;

  table {
    width: 100%;
  }

  td {
    vertical-align: top;

    &:last-child {
      text-align: right;
      line-height: 1.3;
    }
  }

  hr {
    border-color: color("disabled");
    margin: size("gutter") 0;
  }
}

.list_container {
  position: relative;
  flex: 1 1 auto;
  min-height: 0; // needed to make overflow in .list work
  overflow: hidden;
  display: flex;
  border-bottom: 1px solid color("border");

  @media (min-width: breakpoint("small")) {
    max-height: 220px;
  }
}

.list {
  flex: 1;
  overflow: auto;
  overscroll-behavior-y: contain;
}

.shadow {
  &__top, &__bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: .5;
    transition: opacity transition("duration--atoms") transition("ease-ease");
    pointer-events: none;
  }

  &__top {
    box-shadow: inset 0px 15px 20px -22px #000;
  }

  &__bottom {
    box-shadow: inset 0px -15px 20px -22px #000;
  }

  &__hidden {
    opacity: 0;
  }
}

.item {
  padding: size("gutter--small");
  transition: opacity transition("duration--atoms") transition("ease-in");

  & + & {
    border-top: 1px solid color("border");
  }

  &Body {
    display: flex;
    align-items: center;
    min-width: 0;
  }

  &Img {
    margin-right: size("gutter--small");
    width: $img-size;
    height: $img-size;
    border-radius: size("border-radius");
    align-self: flex-start;
  }

  &TextContainer {
    min-width: 0px; // Hack to allow ellipsis inside flex parent
    flex: 1;

    &Top {
      display: flex;
      justify-content: space-between;
    }
  }

  &Name {
    font-weight: 700;
    margin-bottom: 0;
    line-height: font("line-height--heading");
  }

  &Brand, &Qty {
    color: color("text--muted");
    font-size: size("text--small");

    margin-top: 0;
    margin-bottom: 0;
  }

  &Price {
    margin-top: size("gutter--mini");
    text-align: right;
    font-weight: 700;
  }
}

.itemQty, .itemPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    text-align: center;
     margin: 0;
  }
}

.removeItemButton {
  font-size: small;
  font-weight: normal;
  color: color("text--muted") !important;
}

.totals {
  padding: size("gutter");
  flex: 0 0 auto;

  &Row {
    display: flex;
    justify-content: space-between;

    &__grandtotal {
      padding-top: size("gutter");

      &:last-child {
        line-height: font("line-height");
        text-align: right;
      }
    }

    & + & {
      margin-top: size("gutter");
    }
  }
}

.grandTotalCash {
  color: color("text--muted");
  font-size: size("text--small");
}

.cogWrapper {
  display: none;

  @media (max-width: breakpoint("small")) {
    display: inherit;
    flex: 0 0 auto;
    top: -5px;
    right: -5px;
    margin-left: size("gutter");
  }

  .cogIcon {
    color: color("text--muted");
    width: 32px;
    height: 32px;
  }
}



.bottom {
  flex: 0 0 auto;
  padding: size("gutter--small");
  padding-top: 0;

  button {
    width: 100%;
  }

  a {
    display: block;
  }

  a + a {
    margin-top: size("gutter--small");
  }
}
