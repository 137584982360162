@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.heading {
  font-size: size("font--large");
  text-transform: uppercase;
}

.items {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.item {
  padding: size("gutter");
  border: 1px solid color("border--muted");
  border-radius: size("border-radius");

  &.loading {
    opacity: 0.6;
  }

  & + & {
    margin-top: size("gutter");
  }

  &.empty {
    font-size: size("font--small");
    border-color: color("border--muted");
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: inherit;
    &:hover {
      border-color: color("border--muted");
    }
    p {
      margin-bottom: 0;
    }
  }
}

.top {
  display: flex;

  &Left {
    flex: 0 0 auto;
  }

  &Right {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .title {
    font-weight: bold;
  }

  .image {
    width: 64px;
    min-width: 64px;
  }

  .brand, .info {
    margin-top: math.div(size("gutter--mini"), 2);
  }

  .brand {
    font-size: size("font--small");
    color: color("text--muted");
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-left: size("gutter--small");
  }

  .name {
    color: color("text");
    font-weight: bold;

    @media (max-width: breakpoint("tiny") - 1px) {
      font-size: size("font--small");
    }
  }

  .price {
    white-space: nowrap;
    text-align: right;
    margin-left: size("gutter--mini");

    & > div:first-child {
      font-size: size("font");
      font-weight: bold;
    }

    & > div:last-child:not(:first-child) {
      margin-top: math.div(size("gutter--mini"), 2);
      font-size: size("font");
      color: color("text--muted");
      font-style: italic;
      @media (max-width: breakpoint("small") - 1px) {
        margin-bottom: size("gutter") !important;
      }
    }
  }

  .info {
    font-size: size("font--mini");

    @media (min-width: breakpoint("tiny")) {
      text-align: end;
      font-size: size("font--small");
    }

    .positive {
      color: color("primary");
    }

    .negative {
      color: color("secondary");
    }
  }
}

.cta {
  margin-top: size("gutter");
  font-size: size("font--small");
  display: flex;
  justify-content: flex-end;

  .remove {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
      user-select: none;
    }
  }
}
