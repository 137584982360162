@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$blockHeight: 350px;
$levelIconWidth: 100px;

.block {
  display: flex;
  align-items: center;
  min-height: $blockHeight;

  background-image: url("/assets/images/account.jpg");
  @media (min-width: 1500px) { background-image: url("/assets/images/account1920.jpg"); }
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  color: #fff;

  a {
    display: flex;
    align-items: center;
    color: #fff;
  }

  .wrapper {
    width: 100%;
  }

  .split {
    padding: size("gutter") 0;

    @media (min-width: breakpoint("small")) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > div:first-child {
      margin-bottom: size("gutter--large");
    }

    & > div:last-child {
      max-width: 250px;
    }
  }

  .level {
    text-transform: uppercase;
    font-size: size("font--medium");
    font-weight: bold;
  }

  .name {
    font-size: size("font--huge") !important;
    font-weight: 600 !important;
    line-height: font("line-height--heading");
    color: #fff;

    @media (min-width: breakpoint("medium")) {
      font-size: size("font--enormous") !important;
    }
  }

  .icon {
    max-width: $levelIconWidth;
    width: auto;
    height: auto;
  }
}
