@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";


$textMarginLeft: 8px;
$iconSideLength: 13px;

.block{
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  margin-left: size("gutter--large");
  padding: #{math.div(size("gutter--mini"), 2)} 0;
  align-content: center;

  .text {
    margin-left: $textMarginLeft;
    text-transform: capitalize;
    color: color("text");
    font-size: size("font--small");
    bottom: -1px;
  }

  .icon {
    background-color: color("primary");
    border-radius: 1px;
    width: $iconSideLength;
    height: $iconSideLength;

    svg {
      fill: white;
      color: white;
      height: 100%;
      width: 100%;
      vertical-align: unset;
    }
  }
}
