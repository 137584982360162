@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  oneColLayout: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.infoIcon {
  color: color("text--muted");
}

.balance {
  color: color("text--muted");
  margin-top: math.div(size("gutter--mini"), 3);
  margin-bottom: size("gutter--large");
}

.pointSelect {
  @media (min-width: breakpoint("small")) {
    display: flex;

    & > div:last-child {
      margin-left: size("gutter--large");
    }
  }

  @media (max-width: breakpoint("small") - 1px) {
    & > div:last-child {
      margin-top: size("gutter--large");
    }
  }
}

.slider {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sliderLabels {
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: color("text--muted");
}

.summary {
  display: flex;
  justify-content: space-between;

  padding: size("gutter--large") 0 size("gutter--mini") 0;
  margin-top: size("gutter--large");
  border-top: 1px solid color("disabled");

  h2 {
    margin: 0;
  }

  & > div:last-child {
    text-align: right;
  }

  &__label,
  &__value {
    font-size: size("font--medium");
  }

  &__value {
    & > * {
      display: block;

      &:last-child {
        margin-top: math.div(size("gutter--mini"), 2);
      }
    }
  }
}

.processing {
  opacity: .5;
}
