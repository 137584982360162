@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$margin--small: -1.2rem;
$blockHeight: 350px;
$blockHeightSmall: 220px;
$textPaddingRightSmall: 120px;
$imageTransitionDuration: 1000ms;
$imageTransitionDelay: 500ms;
$imageOffSetSmall: -80px;
$breadcrumbsZ: 2;

.block {
  height: $blockHeight;
  background: color("body--off");

  @media (max-width: breakpoint("tiny") - 1px) {
    height: $blockHeightSmall;
  }


  .wrapper {
    height: 100%;
  }

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .breadcrumbs {
      position: absolute;
      top: size("gutter--small");
      left: 0;
      z-index: $breadcrumbsZ;

      @media (min-width: breakpoint("tiny")) {
        top: size("gutter");
      }
    }

    .title {
      text-align: center;
    }

    .itemCount {
      text-align: center;
    }

    .emptyItemCount {
      margin-top: size("gutter--mini");
    }
  }
}
