@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$margin--small: -1.2rem;
$blockHeight: 350px;
$blockHeightSmall: 220px;
$textPaddingRightSmall: 120px;
$imageTransitionDuration: 800ms;
$imageTransitionDelay: 200ms;
$imageOffSetSmall: -80px;
$breadcrumbsZ: 2;
$totalCountHeight: 25px;

.block {
  height: $blockHeight;
  background: color("body--off");

  @media (max-width: breakpoint("tiny") - 1px) {
    height: $blockHeightSmall;
  }
  overflow: hidden;
}

.wrapper {
  height: 100%;
  max-height: 100%;
}

.body {
  position: relative;
  height: 100%;
  max-height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;

  @media (min-width: breakpoint("medium")) {
    padding-right: size("gutter--large") * 4;
  }
}

.breakcrumbs {
  position: absolute;
  top: size("gutter--small");
  left: 0;
  z-index: $breadcrumbsZ;

  @media (min-width: breakpoint("tiny")) {
    top: size("gutter");
  }
}

.text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: $textPaddingRightSmall;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;

  .title {
    margin: 0;
    text-transform: uppercase;
  }

  .total_count {
    margin:0;
    margin-top: size("gutter--mini");
    color: color("text--muted");
    height: $totalCountHeight;
  }

  @media (min-width: breakpoint("tiny")) {
    right: 0;
  }
}

.image {
  flex: 0 1 auto;
  width: auto;
  height: 100%;

  @media (max-width: breakpoint("small") - 1px) {
    right: $imageOffSetSmall;
  }

  & > * {
    height: 100%;
    width: auto;
    max-width: none;
  }
}
