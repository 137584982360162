@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$headerHeight: 70px;

.filter {
  margin: 0 size("gutter--medium");

  &:not(:first-child) {
    border-top: 1px solid color("border--muted");
  }

  @media (min-width: breakpoint("tiny")) {
    margin: 0 #{size("gutter--medium") + size("gutter--large")};
  }

  &__header {
    height: $headerHeight;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color transition("duration--organisms") transition("ease-ease");

    .open & {
      border-bottom-color: color("border--muted");
    }

    & > h5 {
      margin: 0;
    }

    .header__close_icon {
      transition: transform transition("duration--organisms") transition("ease-ease");
      transform: rotateZ(180deg);

      .open & {
        transform: rotateZ(0deg);
      }
    }
  }

  &__body {
    opacity: 0;
    transition: opacity transition("duration--organisms") transition("ease-ease");

    .open & {
      opacity: 1;
    }
  }
}
