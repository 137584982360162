@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$imageWidthIncrease: 10px;

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  overflow: hidden;
  text-decoration: none;
  transition: opacity transition("duration--atoms") linear;
  will-change: opacity;

  &:not(.dummy):hover {
    @media (min-width: breakpoint("small")) {
      text-decoration: none;

      opacity: 0.8;

      .imageWrapper {
        transform: scale(1.03);
      }

      .name {
        text-decoration: underline;
      }
    }
  }
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: transform transition("duration--atoms") transition("ease-ease");
  transform: translateZ(0);
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.imagePixel {
  display: block;
  flex: 0 0 auto;
  max-width: 100%;
  width: 100%;
}

.body {
  padding-top: size("gutter");
  padding-bottom: size("gutter");
  text-align: left;
  // use static value to allow dummy cards
  // TODO: calculate in SASS?
  height: 115px;
  flex: 1 0 auto;
}

.top {
  .wishlistIcon {
    position: absolute;
    top: -2px;
    right: 5px;

    @media (min-width: breakpoint("small")) {
      right: -5px;
    }
  }
}

.name {
  font-weight: 700;
  color: color("text");
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  line-height: font("line-height");
  width: calc(100% - #{size("gutter") * 2});

  @media (min-width: breakpoint("small")) {
    width: calc(100% - #{size("gutter") * 1.5});
  }
}

.brand, .price {
  line-height: 1;
}

.brand {
  font-size: size("font--small");
  color: color("text--muted");
  margin-top: 0;
  margin-bottom: size("gutter--small");
}

.price {
  font-size: size("font--small");
  line-height: font("line-height--heading");
  color: color("text");
  margin-top: 0;
  margin-bottom: 0;
}

.badges {
  position: absolute;
  bottom: size("gutter--small");
  left: size("gutter--small");

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    display: flex;
    align-items: center;
    border-radius: size("border-radius");
    background: color("primary");
    color: #fff;
    font-size: size("font--small");
    padding: math.div(size("gutter--mini"), 2) size("gutter--mini") * .8;
  }

  & > * + * {
    margin-top: size("gutter--mini");
  }

  svg {
    width: 13px;
    height: 13px;
    margin-right: math.div(size("gutter--mini"), 1.5);
  }
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummy {
  .image {
    position: static;
    display: block;
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  span, p {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .name {
    max-width: 50%;
  }

  .brand {
    max-width: 53%;
  }

  .price {
    max-width: 42%;
  }
}
