@import "theme/ui-components/_variables.scss";

.block {
  font-size: size("font--small");
  margin-bottom: size("gutter");

  span {
    color: color("text--muted");
  }

  a {
    line-height: font("line-height--breadcrumbs");
    text-decoration: none;
    color: color("text");

    &:hover {
      text-decoration: underline;
    }
  }
}

.delimeter {
  color: color("text") !important;
}
