@use "sass:math";

@import "theme/ui-components/_variables.scss";

$heightWidthRatio: math.div(18.3, 85);
$logoScandicHeightLarge: 35px;
$logoScandicWidthLarge: ceil(math.div($logoScandicHeightLarge, $heightWidthRatio));
$logoScandicHeightSmall: 22px;
$logoScandicWidthSmall: ceil(math.div($logoScandicHeightSmall, $heightWidthRatio));

.block {
  display: flex;
  align-items: center;
}

.logo {
  flex: 1 0 auto;
  height: $logoScandicHeightSmall;
  width: $logoScandicWidthSmall;

  @media (min-width: breakpoint("small")){
    height: $logoScandicHeightLarge;
    width: $logoScandicWidthLarge;
  }
}
