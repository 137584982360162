@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

$top-space: 35px;
$top-space--mobile: 25px;
$grid-gutter: size("gutter--small");

.block {
  margin-top: -#{size("header-margin")};

  // negate app footer margin and apply here instead to keep background color
  padding-bottom: #{size("gutter--large") * 3};
  margin-bottom: -#{size("gutter--large") * 3};

  .hero1 {
    height: 400px;
  }

  .hero2 {
    height: 300px;
    align-items: center;

    h2 {
      color: #fff;
    }

    &Wrapper {
      align-items: center;
    }
  }
}

.row {
  & + & {
    margin-top: $grid-gutter * 2;
  }
}

.campaignCard {
  color: color("text");
  &:hover {
    text-decoration: none;
    & h2 {
      text-decoration: underline;
    }
  }
}

.col2 {
  @media (min-width: breakpoint("small")) {
    display: flex;
    @include stretch($grid-gutter);

    & > * {
      flex: 1 0 auto;
      padding: 0 $grid-gutter;
      width: 50%;
    }
  }

  a {
    display: block;
  }

  @media (max-width: breakpoint("small") - 1px) {
    & > a + a {
      margin-top: size("gutter");
    }
  }

  h2 {
    margin-bottom: size("gutter--mini");
  }

  p {
    margin-top: 0;
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;
  @include stretch($grid-gutter);
  @media (max-width: breakpoint("small") - 1px) {
    @include stretch(math.div($grid-gutter, 3));
  }

  & > * {
    padding: $grid-gutter;
    @media (max-width: breakpoint("small") - 1px) {
      padding: math.div($grid-gutter, 3);
    }

    width: 25%;
    @media (max-width: breakpoint("medium") - 1px) {
      width: 33.3%;
    }
    @media (max-width: breakpoint("small") - 1px) {
      width: 50%;
    }
  }

  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: color("body--off");
    transition: transform transition("duration--atoms") transition("ease-in"),
    box-shadow transition("duration--atoms") transition("ease-in");
    width: 100%;

    padding: size("gutter");

    @media (max-width: breakpoint("small") - 1px) {
      padding: size("gutter--mini");
      text-decoration: none !important;
    }

    &:hover {
      box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-3px);
    }

    button {
      pointer-events: none;
      font-size: size("font--small");

      @media (max-width: breakpoint("small") - 1px) {
        padding: size("gutter--mini") 0;
        background: none;
        color: color("text");
        box-shadow: none;
        line-height: font("line-height");
      }
    }

    img {
      width: auto;
    }
  }
}

.promo {
  display: block;

  @media (min-width: breakpoint("small")) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  padding: size("gutter--medium");

  background: color("primary");
  color: #fff;

  h2 {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: size("gutter--mini");
  }

  p {
    margin-top: 0;
  }
  &:hover {
    text-decoration: none;
    button {
      text-decoration: underline;
    }
  }

  button {
    @media (max-width: breakpoint("small") - 1px) {
      margin-top: size("gutter");
    }
  }
}

.heading {
  text-transform: uppercase;
  font-weight: bold;
}

.recentlyViewedProducts, .popularProducts, .col2 {
  margin-top: math.div(size("gutter--section"), 2);
}

.popularBrands, .popularCategories, .popularProducts, .recentlyViewedProducts {
  text-align: left;
  content-visibility: auto;

  .link {
    padding: size("gutter") 0 size("gutter-large");
  }

  .box {
    transition: transform transition("duration--atoms") transition("ease-in"),
    box-shadow transition("duration--atoms") transition("ease-in");

    &:hover {
      box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-3px);
    }
  }

  @media (max-width: breakpoint("tiny")) {
  }
}

.popularProducts {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: breakpoint("tiny-small")) {
    .products, h2 {
      order: -1;
    }
    .cta {
      margin: 0 auto;
    }
  }

  .cta {
    display: block;
    margin-bottom: size("gutter");
    flex: 0 1 auto;

    .chevron {
      background: color("primary");
      transform: rotate(180deg);
      color: #fff;
      border-radius: 50%;
      margin-right: size("gutter--mini");
    }

    @media (max-width: breakpoint("tiny-small")) {
      .chevron {
        display: none;
      }
    }
  }
}

.recentlyViewedProducts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: breakpoint("tiny-small")) {
    .products, h2 {
      order: -1;
    }
  }
  .products {
    display: flex;
    @include stretch(size("grid-gutter"));

    .product {
      flex: 0 0 16.666%;
      padding: 0 size("grid-gutter");

      @media (max-width: breakpoint("large") - 1) {
        flex: 0 0 20%;
      }

      @media (max-width: breakpoint("medium") - 1) {
        flex: 0 0 25%;
      }

      @media (max-width: breakpoint("small") - 1) {
        flex: 0 0 33.333%;
      }

      @media (max-width: breakpoint("mini") - 1) {
        flex: 0 0 50%;
      }
    }
  }
}

.popularCategories {
  @media (max-width: breakpoint("tiny")) {
    padding: 0 0 size("gutter");
  }
}

.carouselWrapper {
  user-select: none;
  @media (max-width: breakpoint("tiny")) {
    overflow: hidden;
  }
}

.logos {
  display: flex;
  flex-wrap: wrap;

  img {
    height: 100px;

    @media (max-width: breakpoint("tiny")) {
      height: 50px;
    }
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 21%;
    margin: math.div(size("grid-gutter"), 2);
    min-width: 100px;
    height: 150px;
    border-radius: 3px;
    border: solid 1px color("border");

    @media (max-width: breakpoint("tiny")) {
      flex: 1 0 33%;
      height: 100px;
    }

    @media (max-width: breakpoint("tiny")) {
      height: 80px;
    }
  }
}

.desktop {
  @media (max-width: breakpoint("tiny")) {
    display: none;
  }
}

.popularBrandsButton {
  margin: size("gutter") 0 size("gutter");
  flex: 0 1 auto;
}

.double {
  display: flex;
  flex: 0 1 auto;
  margin: size("gutter") * 2 0;

  > div {
    width: 50%;
  }

  div:first-of-type {
    margin-right: size("gutter--mini");
  }

  @media (max-width: breakpoint("tiny")) {
    display: inherit;
    flex: inherit;
    margin: 0;

    > div {
      width: inherit;
    }

    div:first-of-type {
      margin-right: inherit;
    }
  }
}

.carouselItem {
  background-color: #fff;
  margin: $top-space math.div(size("gutter--mini"), 2);
  cursor: pointer;

  @media (max-width: breakpoint("tiny")) {
    margin: $top-space--mobile math.div(size("gutter--mini"), 2);
  }

  @media (min-width: breakpoint("tiny")) {
    &:hover {
      background-color: color("background--hover");
      text-decoration: underline;
      color: color("text");
    }
  }

  &:hover {
    &:active {
      opacity: inherit;
    }
  }

  .titleWrapper {
    height: 20px;
    top: math.div(-$top-space, 2);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: breakpoint("tiny")) {
      font-size: size("font--small");
      top: math.div(-$top-space--mobile, 2);
      padding: 0 4px;
    }
  }

  .title {
    color: color("text");
    margin: 0;
  }

  .imageWrapper {
    overflow: hidden;
  }

  .image {
    display: block;
    margin: 0 auto;
    top: -$top-space;
    max-width: 200px;
    width: 100%;

    @media (max-width: breakpoint("tiny")) {
      top: -$top-space--mobile;
    }
  }
}

.carouselControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: size("gutter");
}

.heroHeading {
  color: #fff;
}

.heroHeading__bg {
  font-size: size("h2");
  line-height: font("line-height--heading");
  @media (min-width: breakpoint("small")) {
    font-size: size("h1");
  }

  background: rgba(0, 0, 0, 0.6);
  padding-top: size("gutter--mini") * .5;
  padding-bottom: size("gutter--mini") * .3;

  padding-left: size("gutter") * 1.3;
  padding-right: size("gutter") * 1.3;
}
