@import "../ProductCard/styles.scss";

.minimalBody {
  padding-top: size("gutter--mini");
  padding-bottom: 0;
  text-align: center;
  height: auto;
  flex: 1 0 auto;
}

.minimalName {
  font-weight: 700;
  color: color("text");
  display: initial;
  text-overflow: clip;
  white-space: normal;
  line-height: font("line-height--heading");
}
