@use "sass:list";

@import "../ui-components/_variables.scss";
@import "../ui-components/_mixins.scss";

/*
  Global typography rules
*/

$block: "typography";

:global {
  @mixin headings($sizes...) {
    $uniqueId: unique-id();

    %#{$uniqueId} {
      @content;
    }

    @each $size in $sizes {
      h#{$size},
      .h#{$size} {
        @extend %#{$uniqueId};
      }
    }
  }


  @include headings(1, 2) {
    margin: 0;
    font: 700 list.slash(1em, font("line-height--heading")) #{font("header")};
    text-transform: uppercase;
    @include vertical-margins(size("gutter--large"));
  }

  @include headings(3, 4, 5, 6) {
    margin: 0;
    font: 700 list.slash(1em, font("line-height--heading")) #{font("body")};
    @include vertical-margins(size("gutter--large"));
  }

  h1, .h1 {
    line-height: 1;
    font-size: size("h1");
    font-weight: 900;
    color: color("heading");

    @media (max-width: breakpoint("small")) {
      font-size: size("h1--mobile");
    }
  }

  h2, .h2 {
    font-size: size("h2");
    color: color("heading");
    @include vertical-margins(size("gutter"));

    @media (max-width: breakpoint("small")) {
      font-size: size("h2--mobile");
    }
  }

  h3, .h3 {
    font: 100 list.slash(1em, font("line-height--heading")) #{font("body-light")};
    font-weight: 100;
    font-size: size("h3");
    letter-spacing: .02em;
    @include vertical-margins(size("gutter"));

    @media (max-width: breakpoint("small")) {
      font-size: size("h3--mobile");
    }
  }

  h4, .h4 {
    font: 400 list.slash(1em, font("line-height")) #{font("body")};
    font-size: size("h4");
    margin-bottom: size("gutter");

    @media (max-width: breakpoint("small")) {
      font-size: size("h4--mobile");
    }
  }

  h5, .h5 {
    font-size: size("h5");
    @include vertical-margins(size("gutter--small"));
  }

  %anchor {
    color: color("primary");
  }

  p,
  .paragraph {
    line-height: font("line-height");
    @include vertical-margins(size("gutter"));

    &,
    > i,
    > b,
    > em,
    > strong {
      > a {
        @extend %anchor;
      }
    }
  }

  li {
    line-height: font("line-height");
  }

  .lead {
    font-size: font("size--large");
  }

  .anchor {
    @extend %anchor;
  }

  mark {
    padding: .2em;
    background: color("mark");
  }

  strong, b {
    font-weight: 700;
  }

  .#{$block} {
    line-height: font("line-height");

    ol, ul {
      line-height: font("line-height");
      padding-left: size("gutter--small");
      @include vertical-margins(size("gutter--small"));
      list-style-position: inside;

      ul {
        list-style-type: circle;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      line-height: font("line-height");

      + p {
        margin-top: 0;
      }
    }

    table {
      @include vertical-margins(size("gutter--small"));
    }

    td,
    th {
      text-align: left;
      vertical-align: baseline;
      line-height: font("line-height--heading");
    }

    tr {
      &:not(:first-child) {
        td, th {
          padding-top: .5em;
        }
      }

      &:not(:last-child) {
        td, th {
          padding-bottom: .5em;
        }
      }

      &:first-child:last-child {
        td, th {
          padding-bottom: .5em;
        }
      }
    }

    th:not(:first-child) {
      padding-left: size("gutter--small");
    }

    td:not(:first-child) {
      padding-left: size("gutter--small");
    }

    [style*="font-size"] {
      font-size: inherit !important;
    }

    $column-width: size("gutter--section");

    .columns {
      @media (min-width: breakpoint("small")) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax($column-width, 1fr));
        column-gap: size("gutter--large");
        row-gap: size("gutter--large");
      }
    }
    .sticky {
      margin-top: size("gutter--large");

      @media (min-width: breakpoint("small")) {
        margin-top: 0;
        position: sticky;
        top: calc(#{size("header-height") + size("header-margin")} + #{size("gutter--large")});
      }
    }
  }
}
