@import "theme/ui-components/_variables.scss";

$view-transition-ease: transition("ease-ease");
$view-transition-duration: transition("duration--slow");

:export {
  viewDuration: $view-transition-duration;
}

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.onCheckout {
    padding-bottom: 100px;
  }

  &.noticePadding {
    padding-bottom: 150px;

    @media (max-width: breakpoint("small")) {
      padding-bottom: 250px;
    }

    @media (max-width: breakpoint("tiny-small")) {
      padding-bottom: 300px;
    }

    @media (max-width: breakpoint("tiny")) {
      padding-bottom: 350px;
    }

    @media (max-width: breakpoint("mini")) {
      padding-bottom: 400px;
    }
  }
}

.onCheckout {
  background: color("body--off");
}

.top {
  flex: 1 0 auto;
  padding-bottom: size("gutter--large") * 3;

  .onCheckout & {
    padding-bottom: 0;
  }
}

.notices {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: z-index("popup");
  box-shadow: 0 -6px 36px 0 rgba(0, 0, 0, 0.12);

  &Cookie {
    z-index: 1000;
  }

  > div:not(:first-child) {
    border-top: 1px solid color("border--muted");
  }
}

.notificationBar {
  background-color: color("secondary");
  color: color("notice--text");
  border: none;
}

:global {
  .leave {
    width: 100%;
    opacity: 1;
    position: absolute;
  }

  // .leave:not(.leaving) * {
  .leave * {
    animation: none !important;
    opacity: 1 !important;
  }

  .leaving {
    opacity: 0;
    transition: $view-transition-duration opacity $view-transition-ease;
  }

  .enter {
    opacity: 0;
  }

  .entering {
    opacity: 1;
    transition: $view-transition-duration opacity $view-transition-ease;
  }
}

.height {
  height: size("header-height--small") + size("user-header-height");
  margin-bottom: size("header-margin");

  @media (min-width: breakpoint("small")) {
    height: size("header-height") + size("user-header-height") !important;
  }
}

/* Footer */

.footer {
  flex: 0 0 auto;
}

.footerTerms {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: size("grid-gutter");

  @media (max-width: breakpoint("small")) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.footerTermsContainer * {
  position: relative;
  margin-right: size("gutter--mini");
  padding-right: size("gutter--mini");

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 80%;
    width: 1px;
    background-color: color("body");
  }

  &:last-child {
    padding: 0;
    margin: 0;

    &:after {
      display: none;
    }
  }
}

.logo {
  width: fit-content;
    
  @media (min-width: breakpoint("tiny")) {
    margin-left: auto;
  }
}
