@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";


.statusbar {
  background: color("level--number");
  
  &.level4 {
    background: color("level--top");
  }
  
  &.level5 {
    background: color("level--red");
  }
}