@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.row {
  @media (min-width: breakpoint("small")) {
    margin-bottom: math.div(size("gutter--small"), 2);
    @include stretch(math.div(size("gutter--small"), 4));
    @include clearfix();
  }
}

.s100, .s50, .s33 {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("gutter--small"), 4);
    float: left;
  }

  @media (max-width: breakpoint("small") - 1px) {
    margin-bottom: size("gutter--mini");
  }
}

.s100 {
  @media (min-width: breakpoint("small")) {
    width: 100%;
  }
}

.s50 {
  @media (min-width: breakpoint("small")) {
    width: 50%;
  }
}

.s33 {
  @media (min-width: breakpoint("small")) {
    width: 33.333%;
  }
}
/* TODO: fix this in component instead */
.countries main {
  overflow-y: scroll;
  max-height: 350px;
}