@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.mapContainer {
  border-radius: size("border-radius");
  overflow: hidden;
  &__large {
    margin-bottom: size("gutter--large");
    @media (max-width: (breakpoint("medium") - 1px)) {
      //display: none;
    }
  }

  &__small {
    margin-bottom: size("gutter");
    @media (min-width: breakpoint("medium")) {
      display: none;
    }
  }
}

.map {
  background: #88aa88;
  height: 200px;
  transition: height transition("duration--organisms") transition("ease-out"),
    opacity transition("duration--organisms") transition("ease-out");

  @media (max-width: (breakpoint("medium") - 1px)) {
    height: 280px;
  }
}

.mapText {
  margin: 0;
  padding: size("gutter");
  color: color("text--muted");
  background: #fff;
}