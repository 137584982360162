@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/shop-views/src/shop-views/SuccessView.scss";

$itemPadding: 24px;
$imageSize: 80px;

.header {
  .back {
    display: none;
  }
}

.mainTitle {
  .title {
    font-size: size("font--large");
  }

  .orderNo {
    margin-bottom: 0 !important;
    font-size: size("font--medium");
    text-transform: uppercase;
    font-weight: 700;
  }

  .checkIconBG {
    background-color: color("primary");
    margin-right: size("gutter--small");
    border-radius: 50%;
    padding: 0.3rem;
  
    svg {
      width: 50px;
      height: 50px;
      padding: 0.2rem;
    }
  }
}

.mapText {
  padding: size("gutter--small");
  border-radius: size("border-radius");
}

.steps {
  li h3 {
    font-size: size("h2");
    font-weight: 700;
    text-transform: uppercase;
    color: color("heading");
  }
}

.items {
  margin-bottom: size("gutter");
}

.itemInfo {
  .name {
    font-size: size("font--medium");
    font-weight: 700;
  }
}

.success {
  flex-direction: column;

  @media (max-width: (breakpoint("small"))) {
    gap: size("gutter--medium");
  }

  @media (min-width: (breakpoint("small"))) {
    flex-direction: row-reverse;
  }
}

.left,
.right {
  .subHeading {
    display: none;
  }
}
