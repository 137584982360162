@import "theme/ui-components/_variables.scss";

.block {
  @media (min-width: breakpoint("small")) {
    background-color: #fff;
  }

  max-height: 525px;
  overflow-y: auto;
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: size("gutter");

  background: #fff;
  color: color("text");

  & + & {
    border-top: 1px solid color("border--muted");
  }

  @media (min-width: breakpoint("small")) {
    &:hover {
      background: color("body--off");
    }
  }

  .flag {
    margin-right: size("gutter--mini");
    width: 18px;
    height: 18px;
  }

  .tick {
    width: 20px;
    height: 20px;
  }

  span {
    display: block;
    flex: 1 0 auto;
  }

  @media (max-width: breakpoint("small") - 1px) {
    background: color("OOC--color");
    color: #fff;

    & + & {
      border-top: 1px solid color("text--muted");
    }

    &:last-child {
      border-bottom: 1px solid color("text--muted");
    }
  }
}
